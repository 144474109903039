import firebase from 'firebase';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../../global.css';
import {
  Container,
  Title,
  Text,
  Content,
  TextWrapper,
  TermsLink,
  Image,
} from './index.css';
import { Link } from '../../core/button';

const query = graphql`
  query ShopPrizesQuery {
    shopJson {
      prizes {
        title
        text
        cta
        url
        termsCta
        termsUrl
        image {
          childImageSharp {
            fluid(maxWidth: 550, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const ShopPrizes = () => {
  const data = useStaticQuery(query).shopJson.prizes;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <Image fluid={data.image.childImageSharp.fluid} alt={data.title} />
          <TextWrapper>
            <Title>{data.title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: data.text }} />
            <Link
              title={data.cta}
              href={data.url}
              onClick={() => {
                firebase.analytics().logEvent('shop_link_clicked');
              }}
            />
            <TermsLink href={data.termsUrl}>{data.termsCta}</TermsLink>
          </TextWrapper>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default ShopPrizes;
