import firebase from 'firebase';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../../global.css';
import { Link } from '../../core/button';
import { ButtonVariant } from '../../core/button/button.const';
import { Container, Title, Text, Content, TextWrapper, Subtitle, Image } from './index.css';

const query = graphql`
  query ShopHeroQuery {
    shopJson {
      hero {
        title
        subtitle
        text
        cta
        url
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 655, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const ShopHero = () => {
  const data = useStaticQuery(query).shopJson.hero;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <TextWrapper>
            <Subtitle>{data.subtitle}</Subtitle>
            <Title>{data.title}</Title>
            <Text>{data.text}</Text>
            <Link
              title={data.cta}
              variant={ButtonVariant.LIGHT}
              href={data.url}
              onClick={() => {
                firebase.analytics().logEvent('shop_link_clicked');
              }}
            />
          </TextWrapper>
          <Image image={data.image} alt={data.title} style={{ objectFit: 'cover' }} />
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default ShopHero;
