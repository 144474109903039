import styled from 'styled-components';
import { color } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 120px;
  background: ${color.secondary};

  ${MEDIA.MIN_TABLET`
    padding-top: 110px;
  `};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Title = styled.h2`
  font-size: 30px;
  line-height: 36px;
  width: 100%;
  margin-bottom: 10px;
  max-width 600px;
  margin-bottom: 30px;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 19px;
  `};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 50px;
  max-width 500px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 40px;
  `};
`;

export const Video = styled.video`
  width: 100vw;
  margin-top: 60px;
  ${MEDIA.MIN_TABLET`
    width: 100%;
  `};
`;

export const Image = styled(Img)`
  width: 100vw;
  margin-top: 60px;
  ${MEDIA.MIN_TABLET`
    width: 100%;
    max-height: 465px;
  `};
`;

export const VideoWrapper = styled.div`
  ${MEDIA.MIN_TABLET`
    width: 100%;
    max-width: 836px;
    margin-left: auto;
    margin-right: auto;
`}
`;
