import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import { GatsbyGifImage } from '../../core/gatsbyGifImage';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 128px;
  padding-bottom: 83px;
  background: ${color.white};
  text-align: left;

  ${MEDIA.MIN_TABLET`
    padding-top: 120px;
    padding-bottom: 120px;
  `};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: ${font.primaryBold};
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 60px;
  font-size: 30px;
  line-height: 36px;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 70px;
  `};
`;

export const ContentsWrapper = styled.div`
  display: grid;
  width: 100%;
  ${MEDIA.MIN_TABLET`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 53px;
  `};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  ${MEDIA.TABLET`
    margin-bottom: 50px;
  `};
`;

export const Icon = styled(GatsbyGifImage)`
  border-radius: 50%;
  margin-bottom: 20px;
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 40px;
  `};
`;

export const ContentTitle = styled.h5`
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 19px;
`;

export const ContentSubtitle = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 60px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
