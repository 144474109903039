import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import { GatsbyGifImage } from '../../core/gatsbyGifImage';

export const Container = styled.div`
  color: ${color.black};
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  padding-top: 128px;
  padding-bottom: 76px;
  text-align: left;
  background: linear-gradient(180deg, #80b8f6 0%, #dcdcfb 100%);

  ${MEDIA.MIN_TABLET`
    padding-top: 221px;
    padding-bottom: 100px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
  `};
`;

export const TextWrapper = styled.div`
  padding-bottom: 83px;
  ${MEDIA.MIN_TABLET`
    padding-bottom: 0;
  `};
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.1em;
`;

export const Title = styled.h1`
  font-family: ${font.secondary};
  font-size: 40px;
  line-height: 45px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
  white-space: pre-wrap;

  ${MEDIA.MIN_TABLET`
    font-size: 60px;
    line-height: 60px;
  `};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 40px;
  max-width: 300px;
`;

export const Image = styled(GatsbyGifImage)`
  width: 100%;
  max-width: 450px;
  max-height: 409px;
`;
