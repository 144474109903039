import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.textInverted};
  padding-top: 80px;
  padding-bottom: 100px;
  text-align: left;
  background: ${color.readership};

  ${MEDIA.MIN_TABLET`
    padding-top: 90px;
    padding-bottom: 103px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `};
`;

export const TextWrapper = styled.div`
  @media (min-width: 897px) {
    padding-right: 61px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 30px;
  letter-spacing: 0.1em;
`;

export const Title = styled.h2`
  font-family: ${font.secondary};
  font-size: 40px;
  line-height: 45px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 8px;
  white-space: pre-wrap;

  ${MEDIA.MIN_TABLET`
    font-size: 48px;
    line-height: 50px;
  `};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 30px;
`;

export const Image = styled(Img)`
  width: 100%;
  margin-bottom: 44px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 0px;
    margin-right: 44px;
    min-width: 400px !important;
    `};
  @media (min-width: 897px) {
    min-width: 460px !important;
  }
`;
