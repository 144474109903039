import React from 'react';
import Layout from 'components/layout';
import Hero from 'components/shop/hero';
import Video from 'components/shop/video';
import Contents from 'components/shop/contents';
import Readership from 'components/shop/readership';
import Prizes from 'components/shop/prizes';
import Restock from 'components/shop/restock';
import StoreMinimalCtaSection from 'components/storeCtaMinimalSection';
import { graphql } from 'gatsby';

export const query = graphql`
  query VideoQuery {
    allContentfulWebConfig {
      nodes {
        shopVideo {
          file {
            url
          }
        }
      }
    }
  }
`;

const Shop = ({ data }) => {
  const { url } = data.allContentfulWebConfig.nodes[0].shopVideo.file;
  return (
    <Layout>
      <Hero />
      <Video videoUrl={url} />
      <Contents />
      <Readership />
      <Prizes />
      <Restock />
      <StoreMinimalCtaSection />
    </Layout>
  );
};

export default Shop;
