import firebase from 'firebase';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  Container,
  Title,
  Item,
  Icon,
  ContentTitle,
  ContentSubtitle,
  ContentsWrapper,
  Content,
  TextWrapper,
} from './index.css';
import { ContentWrapper } from '../../../global.css';
import { Link } from '../../core/button';

const query = graphql`
  query ShopContentsQuery {
    shopJson {
      contents {
        title
        items {
          title
          subtitle
          cta
          url
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 250, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

const ShopContents = () => {
  const data = useStaticQuery(query).shopJson.contents;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <TextWrapper>
          <Title>{data.title}</Title>
        </TextWrapper>
        <ContentsWrapper>
          {data.items.map(content => (
            <Item key={content.title}>
              <Content>
                <Icon image={content.image} alt={content.title} />
                <ContentTitle>{content.title}</ContentTitle>
                <ContentSubtitle>{content.subtitle}</ContentSubtitle>
              </Content>
              <Link
                href={content.url}
                title={content.cta}
                onClick={() => {
                  firebase.analytics().logEvent('shop_link_clicked');
                }}
              />
            </Item>
          ))}
        </ContentsWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default ShopContents;
