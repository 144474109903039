import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  color: ${color.black};
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  padding-top: 120px;
  padding-bottom: 75px;
  text-align: center;
  background: ${color.grey};

  ${MEDIA.MIN_TABLET`
    padding-top: 120px;
    padding-bottom: 85px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
`;

export const Subtitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.1em;
`;

export const Title = styled.h2`
  font-size: 30px;
  line-height: 40px;
  width: 100%;
  margin-bottom: 20px;
  white-space: pre-wrap;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    line-height: 50px;
  `};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 40px;
  & > b {
    font-family: ${font.primaryBold};
  }
`;

export const Image = styled(Img)`
  width: 100%;
  max-width: 550px;
  margin-bottom: 39px;
`;

export const TermsLink = styled.a`
  color: ${color.primaryCta};
  margin-top: 10px;
  font-size: 14px;
  line-height: 26px;
`;
