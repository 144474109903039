import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../../global.css';
import { Container, Title, Text, Content, TextWrapper, Subtitle, Image } from './index.css';

const query = graphql`
  query ShopReadershipQuery {
    shopJson {
      readership {
        title
        subtitle
        text
        image {
          childImageSharp {
            fluid(maxWidth: 304, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const ShopReadership = () => {
  const data = useStaticQuery(query).shopJson.readership;

  return (
    <Container>
      <ContentWrapper>
        <Content>
          <TextWrapper>
            <Subtitle>{data.subtitle}</Subtitle>
            <Title>{data.title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: data.text }} />
          </TextWrapper>
          <Image fluid={data.image.childImageSharp.fluid} alt={data.title} />
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default ShopReadership;
