import firebase from 'firebase';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../../global.css';
import { Link } from '../../core/button';
import { Container, Title, Text, Content, TextContainer, Image, VideoWrapper } from './index.css';

const query = graphql`
  query ShopVideoQuery {
    shopJson {
      video {
        title
        subtitle
        cta
        url
        image {
          childImageSharp {
            fluid(maxWidth: 836, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const ShopVideo = () => {
  const data = useStaticQuery(query).shopJson.video;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <TextContainer>
            <Title>{data.title}</Title>
            <Text>{data.subtitle}</Text>
            <Link
              href={data.url}
              title={data.cta}
              onClick={() => {
                firebase.analytics().logEvent('shop_link_clicked');
              }}
            />
          </TextContainer>
        </Content>
      </ContentWrapper>
      <VideoWrapper>
        <Image fluid={data.image.childImageSharp.fluid} alt={data.title} />
      </VideoWrapper>
    </Container>
  );
};

export default ShopVideo;
