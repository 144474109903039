import React from 'react';
import Img from 'gatsby-image';

export const GatsbyGifImage = ({ image, alt, ...props }) => {
  if (!image) {
    return null;
  }
  if (image.extension === 'gif') {
    return <img src={image.publicURL} alt={alt} {...props} />;
  }
  return <Img fluid={image.childImageSharp.fluid} alt={alt} {...props} />;
};
